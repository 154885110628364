import React from 'react';
import { Row, Col } from 'antd';
import GoogleMapReact from 'google-map-react';
import './style.scss';

export function Contact() {
    const defaultProps = {
        center: {
          lat: 48.88,
          lng: 2.26
        },
        zoom: 11
      };

    const renderMarkers = (map, maps) => {
        let marker = new maps.Marker({
            position: {
                lat: 48.885803,
                lng: 2.260925,
            },
            map,
            title: 'Regalasie'
        });
    }

    return (
        <div className="contact" id="contact">
            <Row justify="center">
                <Col xs={{span: 22, offset: 1}} sm={{span: 22, offset: 1}} md={{span: 10, offset: 1}}>
                    <div className="map-container">
                        <GoogleMapReact
                            bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAP_KEY }}
                            defaultCenter={defaultProps.center}
                            defaultZoom={defaultProps.zoom}
                            onGoogleApiLoaded={({map, maps}) => renderMarkers(map, maps)}
                            yesIWantToUseGoogleMapApiInternals
                        />
                    </div>
                </Col>

                <Col xs={{span: 22, offset: 1}} sm={{span: 22, offset: 1}} md={{span: 10, offset: 2}}>
                    <div className="contact-info">                    
                        <h2 className="contact-title">CONTACTEZ NOUS</h2>
                        <div className="contact-item">
                            <p>Ouverture :</p> 
                            <p>De lundi à samedi <br/> De 11 : 00h à 21 : 00h <br/> (Il faut passer commande avant 20h45) </p>
                        </div>
                        <div className="contact-item">
                            <p>Fermeture :</p>
                            <p>De 25/12/2021 à 01/02/2022 </p>
                        </div>
                        <div className="contact-item">
                            <p>Adresse :</p> 
                            <p>7 rue du château <br/>  92200 Neuilly sur seine</p>
                        </div>
                        <div className="contact-item">
                            <p>E-mail :</p> 
                            <p>regalasie2020@gmail.com</p>
                        </div>
                        <div className="contact-item">
                            <p>Tél :</p> 
                            <p>06.35.36.10.10</p>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    );
}
