import React from "react";
import { Route } from "react-router-dom";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { Spin } from 'antd';
import './style.scss';

export default ({ component, ...args }) => (
  <Route
    component={withAuthenticationRequired(component, {
      onRedirecting: () =>  <div className="spin-container"><Spin tip="Loading..." size="large"/></div>,
    })}
    {...args}
  />
);