import React from 'react';
import { Layout, Menu, Dropdown } from 'antd';
import { UserOutlined, LogoutOutlined, ProfileOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";
import logoInvert from './logo-invert.png';
import './style.scss';

const { Header: AndtHeader } = Layout;

export function Header() {
  const { isAuthenticated, loginWithRedirect, logout } = useAuth0();

  const menu = (
    <Menu placement="bottomCenter">
      <Menu.Item key="profile" icon={<ProfileOutlined />}>
        <Link to="/profile">Mes informations</ Link>
      </Menu.Item>
      <Menu.Item key="logout" icon={ <LogoutOutlined/>}>
        <a onClick={() => logout({ returnTo: window.location.origin })}>
          Déconnexion
        </a>
      </Menu.Item>
    </Menu>
  );

  const userIcon =  <UserOutlined style={{ fontSize: '24px', color: '#FFFFFF', cursor: 'pointer'}} />

  return (
    <AndtHeader>
      <Link to="/">
        <img src={logoInvert} alt='logo'/>
        <span>Régalasie</span>
      </Link>
      { !isAuthenticated
        ? <span onClick={loginWithRedirect}>
          {userIcon}
          </span>
        : <Dropdown overlay={menu}>
            <span>
              {userIcon}
            </span>
          </Dropdown>
      }
    </AndtHeader>
  );
}
