import { combineReducers } from 'redux';
import { ui } from './ui.reducer';

function normalizeData(data){
   const initialFoodCategoriesState = { byId: {}, allIds: []};
   const initalFoodItemState = { byId: {}, allIds: []};
   for (const category in data){
      initialFoodCategoriesState.byId[category] = {
         items: data[category].map(item => item.id)
      }
      initialFoodCategoriesState.allIds.push(category);

      data[category].forEach((item)=>{
         initalFoodItemState.byId[item.id] = item;
         initalFoodItemState.allIds.push(item.id)
      })
   }
   return {initialFoodCategoriesState, initalFoodItemState}
}

function foodCategories(state = {}, action) {
    switch (action.type) {
      case 'GET_MENU':
        return normalizeData(action.payload).initialFoodCategoriesState
      default:
        return state
    }
}

function formattedFoodData(state = {}, action) {
  switch (action.type) {
    case 'GET_MENU':
      return action.payload
    default:
      return state
  }
}

function foodItems(state = {}, action) {
    switch (action.type) {
      case 'GET_MENU':
        return normalizeData(action.payload).initalFoodItemState
      default:
        return state
    }
}

function orderItems(state = [], action) {
    switch (action.type) {
        case 'ADD_ORDER':
            return [...state, action.payload]
        case 'CLEAN_ORDERS':
            return []
        case 'ORDER_COUNT_CHANGE':
            {
                const { index, value } = action.payload;
                if(value === 0) {
                    return state.filter((e, i) => i !== index);
                }
                
                const newArray = [...state];
                newArray[index] = { 
                    ...state[index],
                    count: value
                }
                return newArray
            }
        default:
            return state
    }
}

export const rootReducer = combineReducers({
    foodCategories,
    foodItems,
    formattedFoodData,
    orderItems,
    ui
})