import React, { useState, useEffect} from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import { Modal, Button, Row, Col } from 'antd';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { MenuOptions} from '../menuOptions';
import { displayPrice } from '../../services';
import { toggleOrderModal, addOrder } from '../../actions';
import './style.scss';

export function OrderModal(props) {
  const [ count, setCount ] = useState(1);
  const [ selectedMenuOptions, setSelectedMenuOptions ] = useState([]);
  const [finishedSelect, setFinishedSelect] = useState(true);
  const { visible, selectedItemId } = useSelector(state => state.ui.orderModal, shallowEqual);
  const item = useSelector(state => state.foodItems.byId[selectedItemId], shallowEqual);
  const dispatch = useDispatch();

  const handleCancel = ()=>{
      dispatch(toggleOrderModal(false));
  }

  const addCommand = () => {
      dispatch(toggleOrderModal(false));
      dispatch(addOrder({
        id: item.id,
        count,
        selectedMenuOptions
      }))
      initState();
  }

  const initState = () => {
    if(item && item.config){
      setFinishedSelect(false);
    }else {
      setFinishedSelect(true);
    }
    setSelectedMenuOptions([]);
    setCount(1);
  }

  useEffect(()=>{
    initState()
  }, [item]);

  if(!item) return null;

  const { title, desc, config, price, unit } = item;

  const handleSelect = (values) => {
    setSelectedMenuOptions(values)
    if(values.filter(value=>value).length === config.length){
      setFinishedSelect(true);
    }
  }

  const forgeFooter = (item) => (
    <div className='modal-footer'>
      <Row gutter={[8,8]}>
        <Col xs={{span:24}} sm={{span:6}}>
          <div className="modal-button-group">
            <Button onClick={() => setCount(count - 1)} icon={<MinusOutlined />} disabled={count<=1} shape="circle" />
              <span>{count}</span> 
            <Button onClick={() => setCount(count + 1)} icon={<PlusOutlined />} shape="circle" /> 
          </div>          
        </Col>
        <Col xs={{span:24}} sm={{offset:2, span:16}}>
          <Button 
            onClick={addCommand} 
            className={`add-command-button 
            ${!finishedSelect ? 'button-disabled': ''}`} 
            disabled={!finishedSelect}
            >
            Ajouter {count} à la commande {displayPrice(count * item.price)}
          </Button>
        </Col>
      </Row>
    </div>
  )

  return (
    <Modal
        title={title}
        visible={visible}
        onCancel={handleCancel}
        footer={forgeFooter(item)}
        wrapClassName="order-modal"
      >
        <div style={{padding: '24px'}}>
          <h2 className="order-modal-title">{title}</h2>
          <div className='food-item-price'>{displayPrice(price)}{unit && ` /${unit}` }</div>
          <div>{desc}</div>
        </div>        
        {
          config && config.length && <MenuOptions config={config} visible={visible} onSelect={handleSelect}/>
        }
    </Modal>
  );
}