import React from 'react';
import { displayPrice } from '../../services'
import './style.css';

export function FoodItem(props) {

    const { id, title, price, unit, desc, img } = props.item;
    const hanldClick = () => {
        props.onClick(id)
    }
    return (
    <div className='food-item' onClick={hanldClick}>
        <div className='food-item-left'>
            <h4 className='food-item-title'>{title}</h4>
            <p className='food-item-desc'>{desc}</p>
            <div className='food-item-price'>{displayPrice(price)}{unit && ` /${unit}` }</div>
        </div>
        <div
          className='food-item-right'
          style={{ backgroundImage:`url(/images/${img}.webp)`, height: img? '157px': 'auto'  }}
        />
    </div>
  );
}