import React, { useState, useEffect } from 'react';
import { Radio } from 'antd';
import './style.scss';

export function MenuOptions({config, visible, onSelect}) {
    const [radioGroupValues, setRadioGroupValues] = useState([]);

    useEffect(()=>{
        if(visible) {
            setRadioGroupValues(config.map(options => null));
        }else{
            setRadioGroupValues([]);
        }
    }, [config, visible]);

    const onChange = (value, index) => {
        const newArray = [...radioGroupValues];
        newArray[index] = value;
        if(newArray.filter(value=>value).length===config.length){
            console.log('all selected')
        }
        onSelect(newArray);
        setRadioGroupValues(newArray);
    }

    return visible ? config.map((options, index)=>
        <section key={options[0]} >
            <div className="option-desc">
                <h4>{options.join(' ou ')}</h4>
                <p>Choisissez-en 1</p>
            </div>
            <Radio.Group 
                onChange={(e)=>onChange(e.target.value, index)} 
                options={options} 
                value={radioGroupValues && radioGroupValues.length && radioGroupValues[index]} 
            /> 
        </section>  
    ) : null
}