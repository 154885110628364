import React from 'react';
import { ThunderboltOutlined, GiftOutlined } from '@ant-design/icons';
import logo from './logo.png';
import './style.scss'

export function Banner() {
  return (
    <section className='banner'>
      <div className="intro">
        <div>
          <span><img src={logo}/></span>  
        </div>
        <h3>Au coeur de Neuilly-sur-Seine. Venez découvrir REGALASIE, créé en 1995, originaire de Shanghai. Je vous fais découvrir des plats aux saveurs authentiques.</h3>
        <div className="button-group">
          <a href="/#foods">
            <div className="button-item">
              <span role='emporter-text'><GiftOutlined /> <span>A emporter &nbsp; -10%</span></span>
              <span>Commandez en ligne ou par téléphone et venez récupérer votre commande au restaurant</span>
            </div>   
          </a>
          <a href="/#foods">
            <div className="button-item">
              <span><ThunderboltOutlined/> En livraison</span>
            </div>  
          </a>
        </div>
      </div>                          
    </section>
  );
}