import React from 'react';
import { Row, Col, Anchor } from 'antd';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useAuth0 } from "@auth0/auth0-react";
import { toggleOrderModal, selectItem } from '../../actions';
import { FoodItem } from '../foodItem';
import { Shopping } from '../shopping';
import './style.scss';

const { Link } = Anchor;

export function Foods() {
  const dispatch = useDispatch();
  // TODO loginWithPopup doesn't seem to have a popup
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const formattedFoodData = useSelector(state => state.formattedFoodData, shallowEqual);

  const handleClick = (id) => {
    if (!isAuthenticated) {
      loginWithRedirect()
      return
    }
    dispatch(selectItem(id));
    dispatch(toggleOrderModal(true))
  }

  return (
    <section className='foods' id='foods'>
        <Anchor className='foods-anchor' targetOffset={100}>
            {
             Object.keys(formattedFoodData).map(key=><Link href={`#${key}`}key={key} title={key} />)
            }     
           <Shopping />
        </Anchor>
        { 
        (()=>{
            const result = [];
            for (const foodCategory in formattedFoodData) {
                const items = formattedFoodData[foodCategory].map((item)=> (
                    <Col xs={{span: 24}} md={{ span: 12 }} xl={{ span: 8 }} xxl={{ span: 6 }} key={item.id}>
                        <FoodItem item={item} onClick={handleClick}/>
                    </Col>                    
                ));

                result.push(
                <div 
                    key={foodCategory} 
                    className='food-category' 
                    style={{ overflow: "hidden", paddingTop: '40px' }}
                    id={foodCategory}
                >                   
                    <Row justify="center"> 
                        <Col xs={22} xl={20} xxl={20}>
                            <h2>
                                <span className="food-category-title">{foodCategory}</span>
                            </h2>                           
                            <Row gutter={[{ xs: 8, sm: 8, md: 16, xl: 24}, { xs: 8, sm: 8, md: 16, xl: 24}]}>
                                {items}
                            </Row>
                        </Col>                    
                    </Row>
                </div>
                )
            }
            return result
        })()
        }
    </section>
  );
}