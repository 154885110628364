export const toggleOrderModal = payload  => ({
    type: 'TOGGLE_MODAL_VISIBILITY',
    payload,
})

export const selectItem = payload => ({
    type: 'SELECT_ITEM',
    payload,
})

export const addOrder = payload => ({
    type: 'ADD_ORDER',
    payload,
})

export const changeNote = payload => ({
    type: 'CHANGE_NODE',
    payload,
})

export const orderCountChange = payload => ({
    type: 'ORDER_COUNT_CHANGE',
    payload,
})

export const toggleShoppingModal = payload => ({
    type: 'TOGGLE_SHOPPING_MODAL',
    payload,
})

export const cleanOrders = () => ({
    type: 'CLEAN_ORDERS'
})

export const getMenu = payload => ({
    type: 'GET_MENU',
    payload
})