const fix2decimal = number => (Math.round(number * 100) / 100).toFixed(2)
export const displayPrice = price => `${fix2decimal(price).replace('.',',')}€`

export const totalPrice = (orderItems, foodItems) => {
    let result = 0;
    if(!orderItems.length) return result;
    orderItems.forEach(item => {
      result = result + foodItems[item.id].price * item.count
    });
    return result
  }

export const setCookie = (name,value,days) => {
  let expires = "";
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + (days*24*60*60*1000));
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}
export const getCookie = (name) => {
  const nameEQ = name + "=";
  const ca = document.cookie.split(';');
  for(let i=0;i < ca.length;i++) {
    let c = ca[i];
    while (c.charAt(0)==' ') c = c.substring(1,c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
  }
  return null;
}