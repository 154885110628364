import { useState, useEffect, useCallback } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { auth0Domain } from "../constants";

export function useUserInfo() {
  const { user, getAccessTokenSilently } = useAuth0();
  const [userInfo, setUserInfo] = useState(null);

  // TODO: error handling
  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          audience: `https://${auth0Domain}/api/v2/`,
          scope: "read:current_user",
        });
        const userDetailsByIdUrl = `https://${auth0Domain}/api/v2/users/${user.sub}`;
        const userDetailsResponse = await fetch(userDetailsByIdUrl, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        const userDetails  = await userDetailsResponse.json();

        setUserInfo({
          ...userDetails,
          user_metadata: {
            ...userDetails.user_metadata,
            username: userDetails?.user_metadata?.username || userDetails.nickname
          }
        });
      } catch (e) {
        console.log(e.message);
      }
    })()
  }, [getAccessTokenSilently, user?.sub]);

  return userInfo
}