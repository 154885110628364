import React from 'react';
import { Layout } from 'antd';
import { Link } from 'react-router-dom';
import './style.scss'


const { Footer: AntdFooter } = Layout;

export function Footer() {
  return (
    <AntdFooter>
        <h2><Link to='/'>Régalasie</Link></h2>
        <p><Link to='/privacy'>Politique de Confidentialité </Link> | © Copyright REGALASIE</p>
    </AntdFooter>
  );
}
