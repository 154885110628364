import React from 'react';
import { useDispatch, useSelector,} from 'react-redux';
import { Select, Input } from 'antd';
import { displayPrice } from 'services';
import { changeNote, orderCountChange } from 'actions';
import './style.scss'

export function OrderModification(props) {
  const dispatch = useDispatch();
  
  const orderItems = useSelector(state=> state.orderItems);
  const foodItems = useSelector(state=> state.foodItems.byId);
  const note = useSelector(state=> state.ui.shoppingModal.note);

  const handleSelectChange = (value, index) => {
    dispatch(orderCountChange ({
      value,
      index
    }));
  }
  const handleChange = (e) => {
    dispatch(changeNote(e.target.value))
  }

    return (
        <section className="order-modification">
        {
          orderItems.map((orderItem, index) => 
            <div key={`orderItem.id_${index}`} className="order-item-container">
              <Select value={orderItem.count} onChange={(e)=>{handleSelectChange(e, index)}}>
                {
                  [...Array(100).keys()].map(x => 
                    <Select.Option value={x} key={x}>{x}</Select.Option>
                  )
                }
              </Select>
              <div className="order-item-desc">
                <div className='order-item-title'>
                  {foodItems[orderItem.id].title}
                </div>
                {
                  orderItem.selectedMenuOptions.length ? 
                  <div className="order-item-options">Avec {orderItem.selectedMenuOptions.join(' et ')}</div> : null
                }
              </div> 
             
              <div className='order-item-price'>
                {displayPrice(foodItems[orderItem.id].price * orderItem.count)}
              </div>
            </div>
          ) 
        }
        <Input placeholder="Note pour le restaurant" value={note} onChange={handleChange}/>
      </section>
  );
}