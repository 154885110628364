import React, { useEffect, useState } from 'react';
import { Layout, Result, Button } from 'antd';
import { useDispatch } from 'react-redux';
import { io } from "socket.io-client";
import { Link } from 'react-router-dom';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import { Foods } from './components/foods';
import { OrderModal } from './components/orderModal';
import { Banner } from './components/banner';
import { Contact } from './components/contact';
import { Confirmation } from './components/confirmation';
import { Header } from './components/header';
import { Footer } from './components/footer';
import { Privacy } from './components/privacy';
import ProtectedRoute from "./HOCs/protectedRoute";
import { Profile } from "./components/profile";
import { setCookie, getCookie } from "./services";
import { getMenu } from "./actions";
import './App.scss';

const { Content } = Layout;

// TODO auth 0 logo
function App() {
  const [showCookie, setShowCookie] = useState(!getCookie('allowCookie'))
  const [showFood, setShowFood] = useState(false)
  const dispatch = useDispatch()

  useEffect(()=>{
    if (window.location.href.includes('/admin')) {
      function playSound(url) {
        const audio = new Audio(url);
        audio.volume = 1;
        audio.play();
      }
      const socket = io();
      socket.on('add_command', ()=>{
        playSound('/sound/notification.wav')
      })
    }
  }, [])

  useEffect(()=>{
    fetch('/menu')
      .then((response)=>response.json())
      .then((json)=> {
        dispatch(getMenu(json))
        setShowFood(true)
      }).catch((e)=>{
        console.error('error fetch menu', e)
      setShowFood(false)
      })
  }, [])


  return (
    <div className="App">
      {showCookie ?
        <div className="cookie-container">
          <div className="cookie-content">
            <span>Ce site utilise des cookies pour que vous puissiez passer des commandes, en l'utilisant, vous acceptez notre politique de cookies disponibles dans notre Politique de Confidentialité</span>
            <Button className="cookie-button" onClick={() => {
              setShowCookie(false);
              setCookie('allowCookie', true, 3)
            }}>
              ok
            </Button>
          </div>
        </div> : null
      }
      <Router>
        <Layout>
            <Header/>
            <Content>
              <Switch>
                <ProtectedRoute path="/confirmation" component={Confirmation} />
                <ProtectedRoute path="/profile" component={Profile} />
                <Route path="/privacy">
                  <Privacy/>
                </Route>
                <Route path="/success">
                  <Result
                    status="success"
                    title="Nous avons bien reçu vos commandes"
                    extra={[<Link key="Accueil" to="/">Accueil</Link>]}
                  />
                </Route>
                <Route path="/error">
                  <Result
                    status="error"
                    title="Commande n'est pas enregisté"
                    subTitle="Veuillez nous contacter pour passer vos commandes"
                    extra={[<Link key="Accueil" to="/">Accueil</Link>]}
                  />
                </Route>
                <Route path="/">
                  <Banner />
                  { showFood ?
                    <>
                      <Foods />
                      <OrderModal />
                    </> : null
                  }
                  <Contact />
                </Route>
            </Switch>
          </Content>
          <Footer/>
        </Layout>
      </Router>
    </div>
  
  );
}

export default App;
