import React, { useEffect } from 'react';
import _ from 'underscore';
import { useDispatch, useSelector,} from 'react-redux';
import { ShoppingOutlined } from '@ant-design/icons';
import {
  Link
} from "react-router-dom";
import { Popover } from 'antd';
import { displayPrice, totalPrice } from 'services';
import { toggleShoppingModal } from 'actions';
import { OrderModification } from '../orderModification';

import './style.scss';

export const Shopping = React.memo(() => {
  const dispatch = useDispatch()

  const orderItems = useSelector(state=> state.orderItems);
  const foodItems = useSelector(state=> state.foodItems.byId);
  const visible = useSelector(state=> state.ui.shoppingModal.visible);

  const handleVisibleChange = (value) => {
    dispatch(toggleShoppingModal(value));
  }

  useEffect(() => {
      window.addEventListener('scroll', _.debounce(handleScroll, 200), { passive: true });
      return () => {
          window.removeEventListener('scroll', handleScroll);
      };
  }, []);

  const handleScroll = () => {
    if( window.innerWidth > 576 ) {
      dispatch(toggleShoppingModal(false))
    }
  }

  const totalCount = (orderItems) =>{
    let result = 0;
    orderItems.forEach(item => {
      result = result + item.count
    });
    return result
  }

  const Content = (<div className="shopping-content">
    <div className="limit-height">
      <OrderModification />
    </div>
    <Link to="/confirmation">
      <div className='confirmation'>
        <span>{totalCount(orderItems)}</span> 
        <span style={{textAlign: 'center'}}>Étape suivante : confirmation </span>
        <span>{displayPrice(totalPrice(orderItems, foodItems))}</span>
      </div>
    </Link>
  </div>)

  return (
    orderItems.length ?
    <div className='shopping'>
      <Popover
        content={Content}
        title={<div className="shopping-content-title">Vos commandes</div>}
        trigger="click"
        visible={visible}
        placement="bottomLeft"
        overlayClassName="shopping-popover"
        onVisibleChange={handleVisibleChange}
      >
        <div className='shopping-icon-container'>
          <ShoppingOutlined style={{color: '#5edf5e'}}/>
          <span style={{color: '#5edf5e', position: 'absolute', fontSize: '16px'}}>{totalCount(orderItems)}</span>
        </div> 
      </Popover>
    </div> : null
  )
})