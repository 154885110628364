import React, {useState, useEffect, useCallback} from 'react';
import { Input, Row, Col, Button, Spin, Form, Popconfirm } from 'antd';
import { useAuth0 } from "@auth0/auth0-react";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { useUserInfo } from "../../hooks";
import { auth0Domain } from "../../constants";
import './style.scss'

const colConfig = {
  xs: {span: 22, offset: 1},
  md: {span:10, offset: 1}
}

export function Profile() {
  const { user, getAccessTokenSilently } = useAuth0();
  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [phone, setPhone] = useState('');
  const [spinning, setSpinning] = useState(false);

  const userInfo = useUserInfo()

  useEffect(()=> {
    if (userInfo?.user_metadata) {
      const { address, telephone, username } = userInfo.user_metadata
      setAddress(address)
      setPhone(telephone)
      setName(username)
    }
  }, [userInfo])

  const updateUserMetadata = useCallback(async() => {
    // TODO error handling
    setSpinning(true)
    try {
      const accessToken = await getAccessTokenSilently({
        audience: `https://${auth0Domain}/api/v2/`,
      });
      const url = `/updateUser`;
      await fetch(url, {
        method: 'PATCH',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "content-type": "application/json",
        },
        body: JSON.stringify({
          user_metadata: {
              address,
              telephone: phone,
              username: name,
            }
          }
        )
      });
      setSpinning(false)
    } catch (e) {
      console.log('updateUserMetadata error', e)
      setSpinning(false)
    }
  }, [getAccessTokenSilently, user?.sub, address, phone, name])

  const deleteUser = useCallback(async() => {
    // TODO error handling
    setSpinning(true)
    try {
      const accessToken = await getAccessTokenSilently({
        audience: `https://${auth0Domain}/api/v2/`,
      });
      const url = `/deleteUser`;
      await fetch(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        }
      });
      setSpinning(false)
      document.location.href="/";
    } catch (e) {
      setSpinning(false)
    }
  }, [getAccessTokenSilently, user?.sub])

  return userInfo ? <section className='profile'>
    <Spin spinning={spinning}>
      <Row>
        <Col {...colConfig}>
          <section>
            <div className='section-title'>
              <h3>Votre profile</h3>
            </div>
          </section>
          <Form  layout="vertical">
            <Form.Item label='Nom et prénom'>
              <Input
                placeholder="Nom et prénom"
                value={name}
                autoComplete="name"
                onChange={(e)=>{
                  setName(e.target.value)
                }}
              />
            </Form.Item>
            <Form.Item label='Adresse e-mail'>
              <Input
                placeholder="email"
                value={userInfo.email}
                disabled
              />
            </Form.Item>
            <Form.Item label='Adresse'>
              <Input
                placeholder="votre adresse"
                value={address}
                autoComplete="address"
                onChange={(e)=>{
                  setAddress(e.target.value)
                }}
              />
            </Form.Item>
            <Form.Item label='Numéro de téléphone'>
              <Input
                placeholder="votre numéro de téléphone"
                type="tel"
                value={phone}
                onChange={(e)=>{
                  const { value } = e.target;
                  if(/^(\d|\+)*$/.test(value)) setPhone(value)
                }}
              />
            </Form.Item>
          </Form>
          <Button className='save-profile-button' onClick={updateUserMetadata}>
            Enregistrer les modifications
          </Button>
          <Popconfirm
            cancelText='Annuler'
            okText='Confirmer'
            title="Etes-vous sûr de supprimer votre compte？" icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
            onConfirm={deleteUser}
          >
            <Button danger>
              Supprimer le compte
            </Button>
          </Popconfirm>
        </Col>
      </Row>
    </Spin>
  </section> : null
}
