import React, { useState, useMemo, useEffect } from 'react';
import { useDispatch, useSelector} from 'react-redux';
import axios from 'axios';
import { Row, Col, Divider, Input, Radio, Checkbox, notification, Spin } from 'antd';
import { displayPrice, totalPrice } from 'services';
import { Link, useHistory } from "react-router-dom";
import { OrderModification } from '../orderModification';
import { cleanOrders } from 'actions'
import { useUserInfo } from "../../hooks";
import './style.scss'

export const Confirmation = React.memo((props) => {
  const [commandType, setCommandType] = useState();
  const [checked, setCheck] = useState(false);
  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [postCode, setPostCode] = useState('');
  const [phone, setPhone] = useState('');
  const [spinning, setSpinning] = useState(false);

  const orderItems = useSelector(state=> state.orderItems);
  const foodItems = useSelector(state=> state.foodItems.byId);
  const note = useSelector(state => state.ui.shoppingModal.note);

  const dispatch = useDispatch();
  const history = useHistory();

  const userInfo = useUserInfo();

  useEffect(()=>{
    if (userInfo?.user_metadata) {
      const { address, telephone, username } = userInfo.user_metadata
      setAddress(address)
      setPhone(telephone)
      setName(username)
    }
  }, [userInfo])


  const total = useMemo(()=> totalPrice(orderItems, foodItems), [orderItems, foodItems])

  const selectCommandType = (e) => {
    setCommandType(e.target.value);
  }

  const isComplete = ()=>{
    if(!checked || !commandType || !name || !phone) return false;

    // if(commandType==='Livraison'){
    //   if(!address || !postCode){
    //     return false
    //   }
    // }
    return true;
  }

  const isEnough = () => {
    return !(commandType === 'Livraison' && total < 20);
  }

  const handleCommand = () => {
    if(!orderItems.length){
      notification.warning({
        message: "Votre command est vide",
        description: "Veuillez ajouter des plates",
      });
      return
    }

    if(!isComplete()) {
      notification.warning({
        message: "Votre info n'est pas complète",
      });
      return
    }

    if(!isEnough()) {
      notification.warning({
        message: "Livraison à partir de 20€ ",
      });
      return
    }

    setSpinning(true);
    sendRequest();
  }

  const sendRequest = () => {
    axios.post('/addCommand', {
      userInfo: {
        name,
        phone,
        commandType,
        // address,
        // postCode
      },
      commandInfo: {  
        items: orderItems.map(orderItem => 
          ({ 
            ...orderItem, 
            ...foodItems[orderItem.id],
            unit: foodItems[orderItem.id].unit || 'Unité',
            totalPrice: foodItems[orderItem.id].price * orderItem.count,
          })
        ),
        note,
        totalPrice: total,
      }
    })
    .then(function (response) {
      dispatch(cleanOrders());
      setSpinning(false);
      history.push("/success")
    })
    .catch(function (error) {
      dispatch(cleanOrders());
      setSpinning(false);
      history.push("/error");
    })
  }
 
  const handlePhoneChange = (e) => {
    const { value } = e.target;
    if(/^(\d|\+)*$/.test(value)){
      setPhone(value)
    }; 
  }

  // const handlePostCodeChange = (e) => {
  //   const { value } = e.target;
  //   if(/^\d*$/.test(value) && value.length <= 5){
  //     setPostCode(value)
  //   };
  // }

  return (
    <section className='confirmation'>
      <Spin spinning={spinning}> 
        <Row>
        

          <Col xs={{span: 22, offset: 1}} md={{span:10, offset: 1}}>
            <section className='order-info'>
              <div className='section-title'>
                <h3>Plats</h3>
                <Link to="/#foods">Ajouter des plats</Link>
              </div>
              <Divider />
              <OrderModification />
              <div className="total-price">
                <span>  Total </span>
                <span>{displayPrice(total)}</span>
              </div> 
              <Divider />
            </section>
          </Col>

          <Col xs={{span: 22, offset: 1}} md={{span: 10, offset: 2}}>
            <section className='user-info' id='user-info'>
              <div className='section-title'>
                <h3>Livrasion ou à emporter</h3>
                <span>Livraison à partir de 20€, distance moins de 2km. </span>
                <span>A retirer en magasin, bénéficiez de 10% de remise à partir de 12€ d’achat. </span>
              </div>
              <Input 
                placeholder="Nom et prénom"
                value={name}
                autoComplete="name"
                onChange={(e)=>{setName(e.target.value)}}
                />
              <Input placeholder="Numéro de téléphone"
                type="tel"
                value={phone} 
                onChange={handlePhoneChange}
              />
              <Radio.Group 
                  onChange={selectCommandType} 
                  options={['Livraison', 'A emporter']} 
                  value={commandType} 
              /> 
              {
                commandType === 'Livraison' ?  
                // <Input.Group>
                //   <Input
                //   placeholder="Address"
                //   value={address}
                //   autoComplete="street-address"
                //   onChange={(e)=>{setAddress(e.target.value)}}
                //   />
                //   <Input
                //     placeholder="Code postal"
                //     inputMode="decimal"
                //     style={{width: '200px'}}
                //     value={postCode}
                //     autoComplete="postal-code"
                //     onChange={handlePostCodeChange}
                //     />
                // </Input.Group>
                  <div className="address-notification">
                    Appeler le magasin (06.35.36.10.10) pour communiquer l'adresse
                  </div>
                : null
              }
              <div className='privacy'>
                <div>
                  <Checkbox checked={checked} onChange={()=>{setCheck(!checked)}}/>
                  <span> J'accepte la <Link to="/privacy"> Politique de Confidentialité </Link>  de Regalasie. </span>
                </div>
                <span>Nous n'utiliserons vos informations que pour la livraison ou à emporter. Alternativement, Vous pouvez passer la commande par téléphone.</span>
              </div>
              <div className={`command ${isComplete() && 'is-complete'}`} onClick={handleCommand}>
                Commander
              </div>
            </section>
          </Col>


        </Row>
      </Spin>
    </section>
  );
})