const initialUiState = {
    orderModal: {
        visible: false,
        selectedItemId: null,
    },
    shoppingModal: {
        visible: false,
        note: '',
    }
}

export const ui = (state=initialUiState, action) => {
    switch (action.type) {
        case 'TOGGLE_MODAL_VISIBILITY':
            return {
                ...state,
                orderModal: {
                    ...state.orderModal,
                    visible: action.payload
                }
            }
        case 'SELECT_ITEM': 
            return {
                ...state,
                orderModal: {
                    ...state.orderModal,
                    selectedItemId: action.payload
                }
            }
        case 'CHANGE_NODE':
            return {
                ...state,
                shoppingModal: {
                    ...state.shoppingModal,
                    note: action.payload
                }
            }
        case 'TOGGLE_SHOPPING_MODAL':
            return {
                ...state,
                shoppingModal: {
                    ...state.shoppingModal,
                    visible: action.payload
                }
            }
        case 'CLEAN_ORDERS':
            return {
                ...state,
                shoppingModal: {
                    ...state.shoppingModal,
                    note: ''
                }
            }
        default:
            return state
    }
}